/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Cookies from "./cookies"
import "./base.css"
import "./theme.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="main-app-wrap">
      <Header siteTitle={data.site.siteMetadata.title}></Header>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/icon.min.css"
        integrity="sha256-KyXPF3/VOPPst/NQOzCWr97QMfSfzJLyFT0o5lYJXiQ="
        crossOrigin="anonymous"
      />
      <link
        type="text/css"
        rel="stylesheet"
        href="https://fast.fonts.net/cssapi/068c8a30-25dc-4c2a-8446-20414976236d.css"
      ></link>
      <div className="app-layout">
        <main>{children}</main>
      </div>

      <div className="_logos_1kqab4 _logos_1kqab4_footer_2">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-12 col-md-3 col-3-m">
              <div
                className="footer-logo footer-logo-apprenticeships"
                style={{
                  backgroundImage:
                    "https://cdn1.accropress.com/LongleatCareers/footer/apprenticeships.png",
                }}
              ></div>
            </div>
            <div className="col-xs-12 col-12 col-md-3 col-3-m">
              <div
                className="footer-logo footer-logo-mindful-employer"
                style={{
                  backgroundImage:
                    "https://cdn1.accropress.com/LongleatCareers/footer/Mindful-Employer-logo-blue.png",
                }}
              ></div>
            </div>
            <div className="col-xs-12 col-12 col-md-3 col-3-m">
              <div
                className="footer-logo footer-logo-disability-employer"
                style={{
                  backgroundImage:
                    "https://cdn1.accropress.com/LongleatCareers/footer/disability-confident.png",
                }}
              ></div>
            </div>
            <div className="col-xs-12 col-12 col-md-3 col-3-m">
              <div
                className="footer-logo footer-logo-armed-forces"
                style={{
                  backgroundImage:
                    "https://cdn1.accropress.com/LongleatCareers/footer/ArmedForces.png",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-layout">
        <Footer />
        <Cookies />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
