import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <div className="_customer-services_1kqab4">
      Any questions? Please contact us at <a href="mailto:recruitment@longleat.co.uk">recruitment@longleat.co.uk</a> or call the HR Team on <br></br>01985 845416.
      </div>
      <div className="_copyright_1kqab4">© Longleat Enterprises Ltd</div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
